
import {
    Typography, Box
} from '@mui/material';
// import "./Register.css";
import avatar8 from '../../assets/images/avatars/R&BD.jpg'; // Replace with the actual path to your image

const GujGovComp = () => {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'static',
                color: 'white',
                background: 'linear-gradient(to bottom, rgba(0,55,96,1), rgba(20,141,227,0.0))',
                padding: 2,
                paddingLeft: 2.5
            }}
        >
            <Box display="flex" mb={1}>
                <Box display="flex" alignItems="center" >
                    <img src={avatar8} alt="gov logo" style={{ maxHeight: '70px', borderRadius: "9.6px" }} />
                </Box>

                <Box
                    sx={{ marginTop: '12px', ml: 2 }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ fontSize: '1.8rem' }} // Increased font size
                    >
                        ROADS AND BUILDINGS DEPARTMENT
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mt: -1, fontSize: '1.8rem' }} // Increased font size
                    >
                        Government Of Gujarat
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default GujGovComp