import React, { useState } from "react";





function Customdropdown({ options, value, onChange }) {

  return (
    <div>
      <div className="mb-3">
        <select className="form-control"
          value={value}
          onChange={onChange} >
          {
            options && options.map((item, index) => (<option value={item.value}>{item.value}</option>))
          }
        </select>
      </div>
    </div>
  );
}

export default Customdropdown;
