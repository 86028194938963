import React, { useState, useRef, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderDivider, CCard, CCardBody, CHeaderNav, CHeaderToggler, CButtonGroup, CButton, CCol, CRow, CFormCheck, CNavLink, CNavItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
// import emblemImage from "public/emblem_small.jpg"
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

// import { AppHeaderDropdown } from './header/index'
import AppHeaderDropdown from './header/AppHeaderDropdownNew'
// import { useEffect } from 'react'
import { UserContext } from '../Contexts/UserContext'

export function DepartmentTitle() {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        borderRight: '2px solid #6c757d',
        paddingRight: '1rem',
        marginRight: '1rem'
      }}>
        <div style={{ marginRight: '0.75rem' }}>
          <img
            src={process.env.PUBLIC_URL + "/emblem_small.jpg"}
            alt="emblemImage"
            style={{ width: 'auto', height: '30px' }}
          />
        </div>
        <h1 style={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          color: '#495057',
          margin: 0
        }}>
          Roads and Buildings Department
        </h1>
      </div>
    </div>
  );
}


function PageTitle({ title }) {
  return (
    <div style={{
      fontSize: '1.1rem',
      fontWeight: '500',
      color: '#6c757d',
      textTransform: 'none',
      letterSpacing: '0.5px'
    }}>
      {title}
    </div>
  );
}

function HeaderComp({ title }) {
  return (
    <header
      style={{
        position: 'sticky',
        backgroundColor: 'inherit',
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        width: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <div
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#343a40',
          }}
        >
          {console.log("title:", title)}

          {title}
        </div> */}
        <DepartmentTitle />
        <PageTitle title={title} />
      </div>
    </header>
  );
}

const AppHeader = ({ isDetails, title, sideBarFlag, setSideBarFlag }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const { activeSelection, setActiveSelection } = useContext(UserContext)
  const { contextUserData } = useContext(UserContext);
  const pathMap = ['wms', 'pp', 'stc']

  useEffect(() => {
    getActiveNav()

  }, [])
  const getActiveNav = () => {
    var path = window.location.pathname.split('/').pop()
    setActiveSelection(path)
  }



  const location = useLocation();
  console.log("type:", location)

  function PageHeadingGiver(path) {
    console.log("path bro path:", path);

    if (contextUserData?.UserRights?.length > 0) {
      console.log("i ran bro:");

      // Loop through each right to find the matching child
      for (let right of contextUserData.UserRights) {
        console.log("right:", right);

        // Find a matching child based on formobjname including the path
        const child = right.children?.find((child) => {
          console.log("childish:", child);
          console.log(child.formobjname?.includes(path));

          return child.formobjname?.includes(path);
        });

        if (child) {
          console.log("formtitle found:", child.formtitle);
          return child.formtitle;  // Return the formtitle if a matching child is found
        }
      }

      console.log("Page not found for path:", path);
    }
  }




  const funcran = PageHeadingGiver(location.pathname.trim())
  console.log('func ran:', funcran)
  const routesToShowHeading = {
    "/home": "Home",

    '/homeBuilding': 'HomeBuildings',
    '/watchlist': 'HomeBuildings',
    '/buildings/:wing': 'Buildings',
    '/print': 'Print', // You might want to remove the extra `PrintView` if it's not being used
    '/dashboard/mmgsy': 'Panchayat (MMGSY)',
    '/dashboard/hrms': 'HRMSDashboard',
    '/dashboard/capital': 'Capital',
    '/dashboard/pmgsy': 'PMGSY',
    '/dashboard/otherroads': 'OTHERROADS',
    '/dashboard/contractor': 'Contractor Registration',
    '/dashboard/scada': 'Scada',
    '/dashboard/apilogs': 'APILOGs',
    '/dashboard/hr': 'HR-Employee Data',
    '/dashboard/hotmixplant': 'HotMixPlant',
    '/dashboard/wms': 'Strobes - (NH,SH,CP)',
    '/dashboard/gujrams': 'GujRAMS',
    '/dashboard/gujmarg': 'GujMarg - Marg Sahayak',
    '/dashboard/nh': 'NationalHighway',
    '/details': 'Details',
    '/register': 'Register',
    '/childTable': 'ChildTable',
    '/datawarehouse': 'RPMS Works',
    '/datawarehouse/RPMS/:data': 'RPMS Works'
  };










  if (isDetails) {
    return (
      <CHeader position="sticky" style={{ marginBottom: '0px !important' }}>
        <CContainer fluid>
          <CHeaderToggler className="ps-1" onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
          {/* <CCol sx={8} style={{maxWidth: '80%'}}>
                  {title}
            </CCol> */}
          <CHeaderNav></CHeaderNav>

          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav>
        </CContainer>
      </CHeader>
    )
  }
  return (
    <CHeader position="sticky" style={{ marginBottom: '0px !important', zIndex: 1 }}>
      <CContainer fluid>

        {/* <CHeaderToggler className="ps-1" onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}> */}
        <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease, transform 0.2s ease",
            }}
            onClick={() => setSideBarFlag(!sideBarFlag)}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#f0f0f0"; // Light hover effect
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent"; // Reset on mouse leave
            }}
            onMouseDown={(e) => {
              e.target.style.transform = "scale(0.95)"; // Button press effect
            }}
            onMouseUp={(e) => {
              e.target.style.transform = "scale(1)"; // Reset on mouse up
            }}
          >
            <CIcon icon={cilMenu} size="lg" />
          </button>

          <HeaderComp title={PageHeadingGiver(location.pathname.trim())} />
        </div>
        {/* </CHeaderToggler> */}

        {/* <div

          style={{ display: 'flex', justifyContent: "center", marginRight: '0px', marginLeft: '0px', width: '41%' }}
        >
          <HeaderComp title={PageHeadingGiver(location.pathname.trim())} />


        </div> */}
        {/* <div style={{ marginRight: "3rem" }}>
        </div> */}


        <CHeaderNav className="ms-3">

          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader >
  )
}

export default AppHeader
