import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Person as PersonIcon,
  ExitToApp as LogoutIcon,

} from '@mui/icons-material';
import { useHistory } from 'react-router-dom'

import { removeCookie } from './../../utils/handleCookie'
// import {}
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { UserContext } from '../../Contexts/UserContext';

const AppHeaderDropdown = ({ avatarSrc }) => {


  const { contextUserData, setContextUserData } = useContext(UserContext)
  console.log("i am here")
  console.log(contextUserData)

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);



  const history = useHistory()
  const logout = async () => {
    localStorage.removeItem("RNB_LOGGED_IN_USER_DATA")

    localStorage.removeItem("jwt_token")
    history.push('/login')
  }

  const changePassword = () => {
    history.push('/changepassword')
  }

  const updateuserdetails = () => {
    history.push('/updateuserdetails')
  }



  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };



  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ display: 'inline-block' }}
    >
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        sx={{
          textTransform: 'none',
          padding: '8px 12px',
          borderRadius: '8px',
          transition: 'background-color 0.3s, box-shadow 0.3s',
          color: '#6b7280', // Default text color
          backgroundColor: open ? "#5B4CE2" : "#e5e7eb",

          '&:hover': {
            backgroundColor: '#5B4CE2', // Button background changes to purple on hover
            opacity: "0.9",
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow on hover
            color: "white", // Make the Button's text color white on hover
            '& .hoverText': {
              color: 'white', // Username turns white on hover
            },
            '& .hoverSubtitle': {
              color: 'white', // Designation turns white on hover
            },
          },
        }}
      >
        <Avatar
          src={avatarSrc}
          sx={{
            width: 40,
            height: 40,
            marginRight: 1,
            backgroundColor: 'white', // Avatar background color
            color: '#5B4CE2', // Avatar text color
            fontSize: '1.2rem', // Font size for initials or text in avatar
          }}
        />
        <Box sx={{ textAlign: 'left' }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', color: open ? "white" : '' }}
            className="hoverText"
          >
            {contextUserData?.LoginUserID || "Username"}
          </Typography>
          <Typography
            variant="body2"
            className="hoverSubtitle"
            sx={{ color: open ? "white" : 'text.secondary' }} // Default text color
          >
            {contextUserData?.UserName || "Designation"}
          </Typography>
        </Box>
      </Button>




      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={4} sx={{ borderRadius: '10px 10px ', minWidth: 200 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px 10px 10px 10px' }}>
                  {/* <Box sx={{ px: 2, py: 1, backgroundColor: '#f5f5f5', borderRadius: '10px 10px 0 0' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {username || 'Username'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      designation
                    </Typography>
                  </Box> */}
                  {/* <Divider /> */}
                  <MenuList
                    autoFocusItem={open}
                    onKeyDown={handleListKeyDown}
                    sx={{ py: 0 }}
                  >
                    {/* <MenuItem
                      onClick={adminPanelClicked}
                      sx={{
                        '&:hover': {
                          opacity: "0.9",
                          backgroundColor: '#0ea5e9', // Sky blue on hover
                          color: "white",
                          borderRadius: '10px 10px 0 0'

                        },
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                      Profile
                    </MenuItem> */}
                    <MenuItem
                      onClick={updateuserdetails}
                      sx={{
                        '&:hover': {
                          opacity: "0.9",
                          backgroundColor: '#0ea5e9', // Sky blue on hover
                          color: "white",
                          borderRadius: '10px 10px 0 0'
                        },
                      }}
                    >
                      <SupervisedUserCircleIcon fontSize="small" sx={{ mr: 1 }} />
                      Update User Details
                    </MenuItem>
                    <MenuItem
                      onClick={changePassword}
                      sx={{
                        '&:hover': {
                          opacity: "0.9",
                          backgroundColor: '#0ea5e9', // Sky blue on hover
                          color: "white"
                        },
                      }}
                    >
                      <SettingsIcon fontSize="small" sx={{ mr: 1 }} />
                      Change Password
                    </MenuItem>
                    {/* <Divider /> */}
                    <MenuItem
                      onClick={logout}
                      sx={{
                        '&:hover': {
                          opacity: "0.9",
                          backgroundColor: '#dc2626', // Light red on hover
                          color: "white",
                          borderRadius: '0 0px 10px 10px'
                        },
                      }}
                    >
                      <LogoutIcon fontSize="small" sx={{
                        mr: 1,
                      }} />
                      Logout
                    </MenuItem>
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box >
  );
};

export default AppHeaderDropdown;
