import { React, useContext, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CNavItem, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { AppSidebarNav } from './AppSidebarNav';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import navigation from '../_nav';
import { UserContext } from '../Contexts/UserContext';
import "../scss/_nav.scss";
import { cilSearch } from '@coreui/icons';
import { DepartmentTitle } from './AppHeader';
const SearchNavGroup = ({ handleSearch = () => { console.log("search") } }) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef(null);

  const handleTogglerOnClick = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <CNavItem key="search-bar" name="search Bar" id="sidebar-search" className="search-container">
      <input
        type="text"
        placeholder="Search..."
        className="search-input"
        onChange={handleSearch}
        ref={inputRef}
      />
      <CIcon icon={cilSearch} customClassName="search-icon" onClick={handleTogglerOnClick} />
    </CNavItem>
  );
};

const AppSidebar = ({ sideBarFlag, setSideBarFlag }) => {
  const sideBarRef = useRef(null);
  const dispatch = useDispatch();
  const { contextUserData } = useContext(UserContext);
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [newNavigation, setNewNavigation] = useState([]); // Holds the filtered or original navigation
  const [sideBarWidthCheck, setSideBarWidthCheck] = useState(0);
  const [searchNavigation, setSearchNavigation] = useState([]); // Holds the search-based navigation
  const [brandShow, setBrandShow] = useState(false)
  // Update navigation based on user context
  useEffect(() => {
    if (contextUserData) {
      try {
        const { normalSideBarArray, searchFeatSideBarArray } = navigation(contextUserData);
        if (normalSideBarArray?.length > 0) {
          setNewNavigation(normalSideBarArray); // Default navigation
          setSearchNavigation(searchFeatSideBarArray); // Search-specific navigation
        }
      } catch (e) {
        console.error('Error loading navigation:', e);
      }
    }
  }, [contextUserData]);

  function handleSearch(e) {
    const searchText = e.target.value;

    if (searchText === "") {
      // Reset navigation to normalSideBarArray if search is cleared
      setNewNavigation(navigation(contextUserData)?.normalSideBarArray || []);
    }

    if (searchText !== "" && searchNavigation?.length > 0) {
      const newFilteredArray = searchNavigation.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );

      setNewNavigation(newFilteredArray);
    }
  }

  return (
    <CSidebar
      className="custom-sidebar"
      position="fixed"
      // ref={sideBarRef}
      unfoldable={true}
      visible={sideBarFlag}
      onHide={() => { console.log("executed"); setBrandShow(false) }}
      onShow={() => { console.log("executed again"); setBrandShow(true) }}
      onVisibleChange={(visible) => {
        {
          console.log("slider executed")
          dispatch({ type: 'set', sidebarShow: visible });
        }
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex " to="/" /> */}

      <CSidebarToggler
        className="d-none d-lg-flex"

      />

      {/* Search bar */}
      <SearchNavGroup handleSearch={handleSearch} />

      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={newNavigation} />
        </SimpleBar>
      </CSidebarNav>


    </CSidebar>
  );
};

export default AppSidebar;
