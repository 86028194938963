import React from 'react'

import { AgGridReact } from 'ag-grid-react'
import { CButton, CFormInput, CRow, CCol, CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react'
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-community';
import './AgGridStyle.css'
import { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { DynamicForm } from './DynamicForm'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from './Loader'
import EditDynamicForm from './EditDynamicForm'
import { tableInfo } from '../../views/dashboard/dummyJson/tabledatajson'
import CIcon from '@coreui/icons-react'
import { cilColorBorder } from '@coreui/icons'
import { onSetTableChange } from '../../redux/commonAction'

ModuleRegistry.registerModules([AllEnterpriseModule.with(AgChartsCommunityModule)]);

LicenseManager.setLicenseKey('For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-21_December_2022_[v2]_MTY3MTU4MDgwMDAwMA==72993978b037cf7071dd20d6c116bd5d')

const EditCustomAgGrid = ({ btnList = [], rowClickedFn = () => { }, module = '', editable = false, ...params }) => {


  const [options, setoption] = useState('')
  const [wing, setwing] = useState('')
  const [loading, setloading] = useState(false)

  const fetchData = async () => {
    setloading(true)
    let res = await localStorage.getItem('table_info')
    let wings = await localStorage.getItem('wing_name')
    setwing(wings)
    let dat = await JSON.parse(res)
    setoption(dat)
    setloading(false)
  }
  React.useEffect(() => {
    fetchData()
  }, [options])




  const gridRef = useRef()
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const { zoom } = useSelector((state) => state.common)

  const [saveBtnVisible, setSaveBtnVisible] = useState(params.title?.includes('Watch List') ? false : true)

  const [schemaData, setSchemaData] = useState({})
  const [gridApi, setGridApi] = useState(null)
  const toolPanel = useRef({ columns: false, filters: false })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [isEditable, setisEditable] = useState(true)

  const [quickFilterText, setQuickFilterText] = useState('')
  const [tableCol, settableCol] = useState([])

  const onFirstDataRendered = useCallback((params) => {
    try {
      if (params.title?.includes('Watch List')) {
        gridRef.current.api.forEachNode((node, key) => {
          if (node.data.watchlist == 1) {
            node.setSelected(true)
          }
        })
      }
    } catch (e) { }
  }, [])

  const saveOnClick = () => {
    const selectedData = gridRef.current.api.getSelectedRows()
    const selectedRowIds = selectedData.map((obj) => {
      return obj.WorkID
    })
    params.saveWatchList(selectedRowIds)
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
      hiddenByDefault: false,
    }
  }, [])

  const handleButtonClick = (e) => {
    if (gridApi) {
      if (e == 'columns') {
        if (!toolPanel.current.columns) {
          gridApi.openToolPanel('columns')
          toolPanel.current.columns = true
          toolPanel.current.filters = false
        } else {
          gridApi.closeToolPanel('columns')
          toolPanel.current.columns = false
        }
      } else {
        if (!toolPanel.current.filters) {
          gridApi.openToolPanel('filters')
          toolPanel.current.columns = false
          toolPanel.current.filters = true
        } else {
          gridApi.closeToolPanel('filters')
          toolPanel.current.filters = false
        }
      }
    }
  }
  const suppressRowHoverHighlight = false
  const columnHoverHighlight = false

  const statusBar = useMemo(() => {
    return {
      statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }, { statusPanel: 'agTotalRowCountComponent', align: 'center' }, { statusPanel: 'agFilteredRowCountComponent' }, { statusPanel: 'agSelectedRowCountComponent' }, { statusPanel: 'agAggregationComponent' }],
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,

      enablePivot: true,
      flex: 1,
      minWidth: 300,
    }
  }, [])

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel()
  }, [])

  const excelStyles = useMemo(() => {
    return [
      {
        id: 'multiline',
        alignment: {
          wrapText: true,
        },
      },
    ]
  }, [])


  const onAddRecord = () => {
    window.open('/addrecord', '_blank')
  }



  function onCellClicked(event) {


    // try {
    //   let workID = event.data.WorkID
    //   let mapLocation = `https://ris.ncog.gov.in/roadstatus/?id=${workID}`
    //   window.open(mapLocation, '_blank', 'noreferrer')
    // } catch (e) {
    //   alert('Unable to redirect to Maps, Please check with Administrator')
    // }

    setModal(false)

    if (event.data == undefined) {
      return
    } else {
      localStorage.setItem('details_tab', JSON.stringify(event.data))
      localStorage.setItem('details_edit', "true")
      window.open('/tabledetails', '_blank')
      setModal(false)
    }
    //if (event.event.target.className.includes('view-class'))
    // if (event.event.target.className.includes('map-class')) {
    //   try {
    //     let workID = event.data.WorkID
    //     let mapLocation = `https://ris.ncog.gov.in/roadstatus/?id=${workID}`
    //     window.open(mapLocation, '_blank', 'noreferrer')
    //   } catch (e) {
    //     alert('Unable to redirect to Maps, Please check with Administrator')
    //   }
    // } else {
    //   setSchemaData(event.data)
    //   setModal(true)
    // }
  }

  function parseDate(str) {
    const monthNames = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    }

    const [month, day, year] = str.split(' ')

    return new Date(year, monthNames[month], day)
  }

  const fetchCol = () => {
    let C = []
    let temp1 = JSON.parse(options)
    let temp = temp1.Data
    C = temp ? temp : tableInfo

    // C.push({
    //   field: 'Action',
    //   pinned: 'right',
    //   width: 150,
    //   minWidth: 150,
    //   maxWidth: 150,
    //   suppressSizeToFit: true,
    //   cellRenderer: BtnCellRenderer,
    //   cellRendererParams: {
    //     clicked: (field) => {
    //       alert(`${field} was clicked`)
    //     },
    //     label: 'Map',
    //   },
    // })
    C.push({
      field: 'Action',
      pinned: 'right',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      suppressSizeToFit: true,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        clicked: (field) => {
          alert(`${field} was clicked`)
        },
        label: 'View',
      },
    })
    settableCol(C)

  }

  useEffect(() => {
    if (options != '') {
      fetchCol()
      btnList.push({ Name: 'Map' })
      let temp1 = JSON.parse(options)
      let temp = temp1.Data

      let circle = [];
      let taluka = [];
      let division = [];
      temp.forEach((element) => {
        circle.push({ "lable": element.Circle, "value": element.Circle })
        taluka.push({ "lable": element.Taluka, "value": element.Taluka })
        division.push({ "lable": element.Division, "value": element.Division })
      })

      let circleData = [];
      let uniqueObject = {};
      for (let i in circle) {
        let objTitle = circle[i]['lable'];
        uniqueObject[objTitle] = circle[i];
      }

      for (let j in uniqueObject) {
        circleData.push(uniqueObject[j]);
      }

      let talukaData = []
      let uniqueObject1 = {};
      for (let i in taluka) {
        let objTitle = taluka[i]['lable'];

        uniqueObject1[objTitle] = taluka[i];
      }

      for (let j in uniqueObject1) {
        talukaData.push(uniqueObject1[j]);
      }

      let divisonData = []
      let uniqueObject2 = {};
      for (let i in division) {
        let objTitle = division[i]['lable'];
        uniqueObject2[objTitle] = division[i];
      }

      for (let j in uniqueObject2) {
        divisonData.push(uniqueObject2[j]);
      }


      localStorage.setItem('circle', JSON.stringify(circleData))
      localStorage.setItem('taluka', JSON.stringify(talukaData))
      localStorage.setItem('division', JSON.stringify(divisonData))


      try {
        let c = []
        for (let j = 0; j < Object.keys(temp[0]).length; j++) {
          if (j == 0) {
            if (params.title?.includes('Watch List')) {
              c.push({
                field: Object.keys(temp[0])[j],
                headerCheckboxSelection: true,
                checkboxSelection: true,
                headerName: Object.keys(temp[0])[j],
              })
            } else {
              continue
            }
          } else {
            c.push({
              field: Object.keys(temp[0])[j],
              headerName: Object.keys(temp[0])[j],
              filter: 'agMultiColumnFilter',
              filterParams: {
                filters: [
                  Object.keys(temp[0])[j].toLowerCase().includes('date') && {
                    filter: 'agDateColumnFilter',
                    filterParams: {
                      // provide comparator function
                      comparator: (filterLocalDateAtMidnight, cellValue) => {
                        const dateAsString = cellValue

                        if (dateAsString == null && dateAsString == '') {
                          return 0
                        }
                        let today = new Date(cellValue)
                        if (isNaN(today.getTime())) {
                          today = parseDate(dateAsString)
                          if (isNaN(today.getTime())) {
                            debugger
                            return null
                          }
                        }
                        if (today < filterLocalDateAtMidnight) {
                          return -1
                        } else if (today > filterLocalDateAtMidnight) {
                          return 1
                        }
                        return 0
                      },
                    },
                  },
                  {
                    filter: 'agNumberColumnFilter',
                  },
                  {
                    filter: 'agSetColumnFilter',
                  },
                ],
              },
            })
          }
        }

        // btnList.forEach((element) => {
        //   c.push({
        //     field: 'Action',
        //     pinned: 'right',
        //     width: 150,
        //     minWidth: 150,
        //     maxWidth: 150,
        //     suppressSizeToFit: true,
        //     cellRenderer: BtnCellRenderer,
        //     cellRendererParams: {
        //       clicked: (field) => {
        //         console.log(field)
        //         alert(`${field} was clicked`)
        //       },
        //       label: 'Edit',
        //     },
        //   })
        // })


        c.push({
          field: 'Edit',
          pinned: 'right',
          width: 150,
          minWidth: 150,
          maxWidth: 150,
          suppressSizeToFit: true,
          cellRenderer: BtnCellRenderer,
          cellRendererParams: {
            clicked: (field) => {
              console.log(field)
              alert(`${field} was clicked`)
            },
            label: 'View',
          },
        })
        setColumns(c)

        setData(temp)
      } catch (e) { }
    }
  }, [options])
  const toggle = () => {
    setModal(!modal)
  }


  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          position: 'top',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          position: 'top',
        },
      ],
      defaultToolPanel: '',
    },
  }

  const BtnCellRenderer = (data) => {
    useEffect(() => { }, [])
    return (
      <>
        <CButton variant="outline" className={data.label === 'View' ? 'view-class' : 'map-class'} color={'success'}>
          {data.label === 'View' ? <CIcon icon={cilColorBorder} height={20} /> : data.label}
        </CButton>
      </>
    )
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <CRow className="p-2 pt-4" style={{ marginTop: '5% important' }}>
        <CCol>
          <CFormInput type="text" placeholder="Search" onChange={(e) => setQuickFilterText(e.target.value)} />
        </CCol>
        <CCol>
          <CButton
            onClick={(e) => {
              handleButtonClick('columns')
            }}
            variant="outline"
            color="primary"
            style={{ margin: '5px 0px', fontWeight: 'bold' }}
          >
            Show/hide Columns
          </CButton>
          <CButton
            onClick={(e) => {
              handleButtonClick('filters')
            }}
            variant="outline"
            color="warning"
            style={{ margin: '5px 5px', fontWeight: 'bold' }}
          >
            Filter
          </CButton>

          {/* <CButton onClick={onBtExport} variant="outline" color="success" style={{ margin: '5px 0px', fontWeight: 'bold' }}>
            Export to Excel
          </CButton> */}

          <CButton onClick={onAddRecord} variant="outline" color="info" style={{ margin: '5px 5px', fontWeight: 'bold' }}>
            Add Record
          </CButton>
          <CButton variant="outline" color="secondary" style={{ margin: '5px', display: params.title?.includes('Watch List') ? 'inline' : 'none', fontWeight: 'bold' }} onClick={saveOnClick}>
            Save WatchList
          </CButton>
        </CCol>
      </CRow>
      <div
        className="ag-theme-material"
        style={{
          height: '45rem',
          zoom: zoom,
        }}
      >
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          suppressRowHoverHighlight={suppressRowHoverHighlight}
          defaultColDef={defaultColDef}
          onCellClicked={onCellClicked}
          target="_blank"
          enableCharts={true}
          statusBar={statusBar}
          quickFilterText={quickFilterText}
          columnHoverHighlight={columnHoverHighlight}
          groupSelectsAll={true}
          onFirstDataRendered={onFirstDataRendered}
          rowSelection="multiple"
          rowGroupPanelShow={'always'}
          columnGroupPanelShow={'always'}
          cellSelection={'true'}
          suppressAutoSize={false}
          ref={gridRef}
          excelStyles={excelStyles}
          gridOptions={gridOptions}
          animateRows={true}
          onGridReady={(params) => {
            setGridApi(params.api)
          }}
        />
      </div>

      {modal && (
        <CModal size="xl" visible={modal} onClose={toggle}>
          <CModalHeader closeButton>Details</CModalHeader>
          <CModalBody>{isEditable ? <EditDynamicForm data={schemaData} wing={wing}></EditDynamicForm> : <DynamicForm data={schemaData}></DynamicForm>}</CModalBody>
          <CModalFooter></CModalFooter>
        </CModal>
      )}
    </>
  );
}

export default EditCustomAgGrid
