import { Dashboard } from "@mui/icons-material"
// import { config } from "src"
// export const BASE_URL = 'https://margsahayak.gujarat.gov.in'
export const LOGIN = 'api/v1/client/login'
export const SERVICES = 'Services'
export const CHANGEPASSWORD = 'ChangePassword'
export const SERVICES1 = 'Services1'
export const SERVICES2 = 'Services2'
export const WINGS = 'Wings'
export const DIVISION = 'Division'
export const OFFICE = 'Office'
export const CHARTS = 'GetCharts'
export const SCADA = 'Scada'
export const SAVEDATA = 'SaveData'
export const REFETCHDATA = 'RefetchData'
export const ALLWORKS = 'GetAllWorks'
export const HOTMIX = 'GetHotMixData'
export const MMGSYAPIDATA = 'MMGSYAPIDATA'
export const MMGSYROADDATA = 'MMGSYROADDATA'
export const AgGridFilter = 'AgGridFilter'
export const AgGridFilterChart = 'AgGridFilterChart'
export const APILOG = 'APILOG'

export const HOTMIXDATA = 'GetHotMixPlantData'
export const ALLWORKSDATA = 'GetAllWorkData'
export const HOMECHARTS = 'GetHomeCharts'
export const HOMECHARTS_Temp = 'GetAllCharts_Temp'
export const CHARTLOGIN = 'Login'
export const COMMONCHARTS = 'GetAllCharts_Common'

export const HOMECHARTSDATA = 'GetHomeChartsData'
export const REMOVEDATA = 'RemoveData'
export const RefetchDataExternal = 'RefetchDataExternal'

export const TRANSFERDATA = 'TransferData'

export const MMGSYBRIDGEDATA = 'MMGSYBRIDGEDATA'

export const HOMECHARTSDATA_Temp = 'GetHomeChartsData_Temp'

export const CHARTSWITHFILTER = 'GetChartsWithFilter'
export const ChartTableDetails = 'ChartTableDetails'
export const CHILDTABLEDETAILS = 'ChildTableDetails'
export const Filters = 'GetFilters'
export const Modules = 'GetModules'
export const GETDATA = 'GetData'
export const WatchList = 'WatchList'
export const GetApiLogData = 'GetApiLogData'

export const SaveUpdateData = 'SaveUpdateData'

export const VALIDATE_USER = "ValidateUser"
export const FORGOT_USERID = "ForgotUserID"
export const FORGOT_PASSWORD = "ForgotPassword"
export const UPDATE_USER = "UpdateUser"
export const FETCH_USER_DETAILS = "FetchUserDetails"
export const CHANGE_PASSWORD = "ChangePassword"
export const FETCH_OFFICE = "FetchOffice"
export const FETCH_DESIGNATION = "FetchDesignation"
export const FETCH_MODULE = "FetchModule"
export const CREATE_USER = "CreateUser"
export const GET_MENU_RIGHT_BASED = "GetMenuRightBased"
export const FETCH_API_DATA = "FetchAPIData"
export const GET_TOTAL_COUNT = "GetTotalCNT"
export const SEARCH_WITH_PAGING = "SRCHWITHPAGING"
export const LIST = "LIST"
export const SOURCE_MMGSY = "MMGSY"
export const SOURCE_STROBES = "STROBES"
export const FETCH_MENU_CODEWISE_OBJECTLIST = "FetchMenucodewiseObjectList"
export const FETCH_OBJECTWISE_DATASET = "FetchObjectwiseDataSet"
export const CREATE_NEWSESSION_FOR_OBJECT_FILTER_REQUEST = "CreateNewSessionForObjectFilterRequest"
export const FETCH_OBJECTWISE_FILTERS = "FetchObjectwiseFilters"
export const FETCH_FILTERWISE_DATA = "FetchFilterswiseData"
export const FILTER_VALUE_SELECTED = "FilterValueSelected"
export const FETCH_OBJECTWISE_DATASET_FILTERED = "FetchObjectwiseDataSetFiltered"
export const REGISTER_GRIEVANCE = "RegisterGrievance"


// export const FILTER_URL = 'http://localhost:50252/webservice/Filters.asmx'

// export const CHARTS_URL = 'http://localhost:50252/webservice/Charts.asmx'

// export const DATAWAREHOUSE_URL = 'http://localhost:50252/webservice/Datawarehouse.asmx'

// export const FILTER_URL = 'http://localhost/dashboardWS/webservice/Filters.asmx'
// export const CHARTS_URL = 'http://localhost/dashboardWS/webservice/Charts.asmx'

//OG one
// export const FILTER_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Filters.asmx'
// export const CHARTS_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Charts.asmx'
// export const DATAWAREHOUSE_URL = 'http://103.27.120.198/dashboardWSTest/webservice/Datawarehouse.asmx'


// const serverIP = process.env.REACT_APP_SERVER_IP




// import { Dashboard } from "@mui/icons-material";

// Declare config object and constants at the top level
// export let config = {};

export let CK_Dsh_DBLogin;
export let CK_Dsh_Strobes;
export let CK_Dsh_MMGSY;
export let CK_Dsh_Gujrams;
export let CK_Dsh_Gujmarg;
export let CK_Dsh_DBData;
export let MAP_URL;
export let MAP_URL_ACCESS_TOKEN;

export let FILTER_URL = 'FILTER_URL_PLACEHOLDER';
export let CHARTS_URL = 'CHARTS_URL_PLACEHOLDER';
export let DATAWAREHOUSE_URL = 'DATAWAREHOUSE_URL_PLACEHOLDER';
export let DASHBOARD_URL = 'DASHBOARD_URL_PLACEHOLDER';
export let WSMIS_URL = 'WSMIS_URL_PLACEHOLDER';
export let WS_DASH_URL = 'WS_DASH_URL_PLACEHOLDER';


// Dynamically fetch the configuration file
export const loadConfig = (config) => {
    console.log("config is here:", config)

    CK_Dsh_DBLogin = config.CK_Dsh_DBLogin;
    CK_Dsh_Strobes = config.CK_Dsh_Strobes;
    CK_Dsh_MMGSY = config.CK_Dsh_MMGSY;
    CK_Dsh_Gujrams = config.CK_Dsh_Gujrams;
    CK_Dsh_Gujmarg = config.CK_Dsh_Gujmarg;
    CK_Dsh_DBData = config.CK_Dsh_DBData;
    MAP_URL = config.MAP_URL;
    MAP_URL_ACCESS_TOKEN = config.MAP_URL_ACCESS_TOKEN;

    // Now that the config values are set, we can construct the URLs
    FILTER_URL = `${CK_Dsh_DBData}/dashboardWS/webservice/Filters.asmx`;
    CHARTS_URL = `${CK_Dsh_DBData}/dashboardWS/webservice/Charts.asmx`;
    DATAWAREHOUSE_URL = `${CK_Dsh_DBData}/dashboardWS/webservice/Datawarehouse.asmx`;
    DASHBOARD_URL = `${CK_Dsh_DBData}/dashboardws_v2/webservice/wsWDMS.asmx`;
    WSMIS_URL = `${CK_Dsh_DBData}/dashboardWS_V2/webservice/wsmis.asmx`;
    WS_DASH_URL = `${CK_Dsh_DBData}/dashboardWS_V2/webservice/wsDash.asmx`;

    console.log("DASHBOARD_URL:", DASHBOARD_URL);

};

// Call loadConfig when the app starts
// loadConfig();

// export const FILTER_URL = 'https://api.rnbdashboard.com/webservice/Filters.asmx'
// export const CHARTS_URL = 'https://api.rnbdashboard.com/webservice/Charts.asmx'
// export const DATAWAREHOUSE_URL = 'https://api.rnbdashboard.com/webservice/Datawarehouse.asmx'

// export const FILTER_URL = 'http://localhost/dashboardWS/webservice/Filters.asmx'

// export const CHARTS_URL = 'http://localhost/dashboardWS/webservice/Charts.asmx'

// export const DATAWAREHOUSE_URL = 'http://localhost/dashboardWS/webservice/Datawarehouse.asmx'
