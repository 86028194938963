import React, { useContext, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilSearch, cilMenu } from '@coreui/icons'
import { CBadge } from '@coreui/react'

import { NavItem } from 'react-bootstrap'
import "../scss/_nav.scss"
import { UserContext } from '../Contexts/UserContext'
export const navLink = (name, icon, badge) => {
  return (
    <>
      {icon && icon}
      {name && name}
      {badge && (
        <CBadge color={badge.color} className="ms-auto">
          {/* {console.log("badge text is here:", badge.text)} */}
          {badge.text}
        </CBadge>
      )}
    </>
  )
}
export const AppSidebarNav = ({ items }) => {
  const location = useLocation()
  console.log("location:", location)
  const { contextUserData, setContextUserData } = useContext(UserContext);

  const navItem = (item, index) => {
    const { component, name, badge, icon, props, className, ...rest } = item


    console.log(item)
    console.log("badge text is here:", badge)
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
          component: NavLink,
        })}
        key={index}
        className={className}
        onClick={() => {

          // setContextUserData((prev) => ({ ...prev, activeTab: rest?.code }))
          // console.log("rest:", rest)
        }
        }

        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component >
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >

        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {
        items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))
      }
    </React.Fragment >
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default React.memo(AppSidebarNav)


