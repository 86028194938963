import React from 'react'

const AppFooter = ({ handleFooterFormOpen = () => { console.log("this function opens footer form") } }) => {
  return (
    <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', color: '#000', padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: 10 }}>
      <div></div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <div style={{ color: '#000', fontSize: '24px' }}>
            <i className="fas fa-mobile-alt"></i>
          </div>
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            For Queries Call
            <span
              style={{
                fontWeight: '600',
                fontSize: '18px',
                cursor: 'pointer',
                transition: 'color 0.3s, text-decoration 0.3s',
                marginLeft: "5px"
              }}
              onMouseEnter={(e) => {
                e.target.style.color = '#5B4CE2'; // Change to purple on hover
                e.target.style.textDecoration = 'underline'; // Underline on hover
              }}
              onMouseLeave={(e) => {
                e.target.style.color = '#000'; // Default text color
                e.target.style.textDecoration = 'none'; // Remove underline on mouse leave
              }}
            >
              +91 9714105866
            </span>
          </span>
        </div>
        <span style={{ color: '#000', fontSize: '16px' }}>or</span>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <button
            onClick={handleFooterFormOpen}
            style={{
              fontWeight: "500",
              textTransform: 'none',
              padding: '8px 12px',
              borderRadius: '8px',
              backgroundColor: '#e5e7eb', // Default gray background
              color: '#6b7280', // Default text color
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for hover
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#5B4CE2'; // Change to purple on hover
              e.target.style.color = 'white'; // Change text color to white on hover
              e.target.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.1)'; // Optional subtle shadow on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#e5e7eb'; // Return to gray on mouse leave
              e.target.style.color = '#6b7280'; // Default text color
              e.target.style.boxShadow = 'none'; // Remove shadow when not hovering
            }}
          >
            Register Grievance

          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AppFooter)
