import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  // useTheme,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import LockResetIcon from '@mui/icons-material/Lock';
import LockResetIcon from '@mui/icons-material/LockReset';
import toast, { Toaster } from 'react-hot-toast';
import ChangePasswrd from './PasswordChange.api.js';
import { UserContext } from '../../../Contexts/UserContext.jsx';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom.min.js';
import { DASHBOARD_URL, CHANGE_PASSWORD } from '../../../constants/api.config.js';
import axios from 'axios';
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GujGovComp from "../../../components/shared/GujGovComp";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BG2 from "../../../assets/images/BG2.jpg"
import HomeIcon from '@mui/icons-material/Home';


const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { contextUserData } = useContext(UserContext)
  // const username = localStorage.getItem('loggedInUsername');
  // const userData = JSON.parse(localStorage.getItem('RNB_LOGGED_IN_USER_DATA')) || null;
  const history = useHistory()
  const notify = (message, type) => toast[type](message);


  const handleReset = () => {
    setOldPassword("")
    setNewPassword("")
    setRepeatPassword("")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (oldPassword === newPassword) { notify("old password and new password can not be same", "error"); setLoading(false); return }
    if (newPassword !== repeatPassword) { notify("passwords doesn't match", "error"); setLoading(false); return }

    if (!contextUserData?.UserID) {
      localStorage.removeItem("RNB_LOGGED_IN_USER_DATA")
      localStorage.removeItem("jwt_token")
      history.push("/login")
      return
    }
    const params = new URLSearchParams({
      UserCode: contextUserData?.UserID.trim(),
      OldPassword: oldPassword,
      NewPassword: newPassword,

    });

    const config = {
      method: 'GET',
      url: `${DASHBOARD_URL}/${CHANGE_PASSWORD}?${params.toString()}`,
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    };
    console.log(`${DASHBOARD_URL}/${CHANGE_PASSWORD}?${params.toString()}`)
    try {
      const result = await axios(config);
      console.log(result)
      const { ErrCode, ErrMsg } = result.data.ErrMsg[0];

      if (ErrCode === "1" && ErrMsg.includes("Timeout")) {
        notify("Response Timeout", "error");
      } else if (ErrCode === "1" && ErrMsg.includes("does not exist !")) {
        notify("User Does Not Exist", "error");
      }
      else if (ErrCode === "1" && ErrMsg.includes("Incorrect Old Password")) {
        notify("Incorrect Old Password", "error");
      }
      else if (ErrCode === "1" && ErrMsg.includes("Maximum attempts")) {
        notify("Maximum Attempts Reached", "error");
      }
      else if (ErrCode === "1" && ErrMsg.includes("Your Password Has been Changed")) {
        notify("Your Password Has been Changed", "success");
      }
    } catch (error) {
      notify("Server Error", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>{contextUserData.UserID && <div style={{
      backgroundImage: `url(${BG2})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // height: '100vh',
      // display: 'flex',
      justifyContent: 'space-around',
      // alignItems: 'center'
    }}>
      <GujGovComp />
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center'
        }}
      >
        <Toaster />
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: 400,
            padding: 4,
            borderRadius: 2,
            boxShadow: 2,
            backgroundColor: "white"
          }}
        >

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <LockResetIcon sx={{ fontSize: isMobile ? 40 : 90, color: theme.palette.primary.main }} />
          </Box>
          <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
            Change Password
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            User: {contextUserData?.UserID.trim()}
          </Typography>

          <TextField
            fullWidth
            label="Old Password"
            variant="filled"
            type="text"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="New Password"
            variant="filled"
            type="text"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            variant="filled"
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            required
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 2 }}> <Button
            type="submit"

            variant="contained"
            color="success"
            disabled={loading}
            sx={{
              width: "33%",
              mt: 0,
              padding: 1,
              borderRadius: 1,
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: 2,
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>

            <Button

              variant="contained"
              color="primary"

              sx={{
                width: "33%",
                mt: 0,
                padding: 1,
                borderRadius: 1,
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: 2,
                },
              }}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              onClick={() => history.push("/home")}
              variant="contained"
              color="error" // Use MUI's default red color for the button
              endIcon={<HomeIcon />}
              sx={{
                width: "33%",
                mt: 0,
                padding: 1,
                borderRadius: 1,
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: 2,
                },
              }}
            >
              Home
            </Button></Box>

        </Box>
      </Container></div>


    }</>
  );
};

export default ChangePassword;
