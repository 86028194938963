import React from 'react'
const Register = React.lazy(() => import('./views/pages/Register/Register3'))
const Login = React.lazy(() => import('./views/pages/login/newLogin'))
const MapComponent = React.lazy(() => import('./views/dashboard/MapComponent'))
const HomeNew = React.lazy(() => import('./views/dashboard/NewComponents/HomeNew'))

const staticRoutes = [
  // {
  //   path: '/home',
  //   name: 'HomeNew',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },
  // {
  //   path: '/dashboard/gujmarg',
  //   name: 'GujMarg',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },
  // {
  //   path: '/dashboard/gujrams',
  //   name: 'GujRams',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },

  // {
  //   path: '/dashboard/contractor',
  //   name: 'CONTRACTOR',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },
  // {
  //   path: '/dashboard/hr',
  //   name: 'HR',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },
  // {
  //   path: '/dashboard/scada',
  //   name: 'SCADA',
  //   component: HomeNew,
  //   exact: true,
  //   shouldCardRender: true,
  //   shouldChartRender: true
  // },
  {
    path: '/', exact: true, name: 'Home', components: HomeNew, shouldCardRender: true,
    shouldChartRender: true
  },
  { path: '/home/Map', exact: true, component: MapComponent, name: 'MapComponent' },
  { path: '/register', name: 'Register', component: Register, exact: true },

  // { path: '/home', exact: true, component: HomeNew, name: 'HomeNew' },
  // { path: '/home', exact: true, component: Home, name: 'HomeNew' },
  // { path: '/dashboard/scada', name: 'SCADA', component: SCADA, exact: true },
  // { path: '/dashboard/hr', name: 'HR', component: HR, exact: true },
  // { path: '/dashboard/wms', name: 'WMS', component: MainDashboard, exact: true },
  // { path: '/dashboard/gujrams', name: 'GujRams', component: GujRamsNew, exact: true },
  // { path: '/login', name: 'Login', component: Login },
  // { path: '/testroute', name: 'test route', component: DefaultLayout2, exact: true },
]

export default staticRoutes
// /dashboard/gujmarg