import React, { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import staticRoutes from "../routes";
// import DataView from '../views/dashboard/NewComponents/DataView';
// import dynamicRoutes from './routes/dynamicRoutes';
// import CustomLoader from './components/CustomLoader';
const HomeNew = React.lazy(() => import('../views/dashboard/NewComponents/HomeNew'));
const DataView = React.lazy(() => import('../views/dashboard/NewComponents/DataView'));
import { UserContext } from '../Contexts/UserContext';

const AppContent = ({ AllowedRoutes }) => {
  const { contextUserData } = useContext(UserContext);

  // Ensure dynamicRoutes is always an array
  let dynamicRoutes = contextUserData?.RegisteringRoutes?.length > 0 ? contextUserData.RegisteringRoutes : [];




  console.log("dynamicRoutes1:", dynamicRoutes);

  dynamicRoutes = dynamicRoutes?.length > 0 ? dynamicRoutes.map((route) => {
    console.log("route:", route);
    return {
      ...route,
      exact: Boolean(route.exact),
      component: route.component === "HomeNew"
        ? React.lazy(() => import('../views/dashboard/NewComponents/HomeNew'))
        : route.component === "Dataview"
          ? React.lazy(() => import('../views/dashboard/NewComponents/DataView'))
          : null
    };
  }) : [];

  console.log("dynamicRoutes:", dynamicRoutes);
  console.log("contextUserData from app content:", contextUserData);

  const allRoutes = [...staticRoutes, ...dynamicRoutes];
  console.log("allroutes:", allRoutes);

  const renderRoute = (route, hasAccess, props) =>
    hasAccess ? (
      <route.component
        {...props}
        shouldCardRender={Boolean(route.shouldCardRender)}
        shouldChartRender={Boolean(route.shouldChartRender)}
        sourceName={route.sourcename}
        databasepaging={route.databasepaging}
      />
    ) : (
      <Redirect to="/unauthorized" />
    );

  return (
    AllowedRoutes?.length > 0 && (
      <CContainer lg className="p-0 m-0">
        <Suspense fallback={<div>loading...</div>}>
          <Switch>
            {allRoutes.map((route, idx) => {
              const hasAccess = AllowedRoutes.includes(route.path);
              return (
                <Route
                  key={idx}
                  path={route.path.trim()}
                  exact={route.exact}
                  render={(props) => renderRoute(route, hasAccess, props)} // Fixed: returning the result of renderRoute
                />
              );
            })}
          </Switch>
        </Suspense>
      </CContainer>
    )
  );
};

export default React.memo(AppContent);
