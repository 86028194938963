import { React, useContext, useState, useEffect } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { UserContext } from '../Contexts/UserContext'
import FooterForm from 'src/views/dashboard/NewComponents/FooterForm'

const DefaultLayout = ({ loadingFlag }) => {
  const { contextUserData } = useContext(UserContext)
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [footerFormOpen, setFooterFormOpen] = useState(false);
  const [sideBarFlag, setSideBarFlag] = useState(true)

  useEffect(() => {
    if (contextUserData?.AllowedRoutes?.length > 0) {

      const tempAllowedRoutes = [...contextUserData?.AllowedRoutes]
      const otherAllowedRoutes = ["/homeBuilding", '/home/ALL', '/watchlist', "/testroute", "/oldhome"]
      const newAllowedRoutesArray = tempAllowedRoutes.concat(otherAllowedRoutes)
      // setAllowedRoutes(contextUserData?.AllowedRoutes)
      setAllowedRoutes(newAllowedRoutesArray)


    }
  }, []);
  const handleFooterFormOpen = () => setFooterFormOpen(true);
  const handleFooterFormClose = () => setFooterFormOpen(false);


  return (
    <div>
      <AppSidebar sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
        <div className="body flex-grow-1 p-3">
          <FooterForm open={footerFormOpen}
            onClose={handleFooterFormClose}
          />
          <AppContent loadingFlag={loadingFlag} AllowedRoutes={allowedRoutes} />
        </div>

      </div>
      <AppFooter handleFooterFormOpen={handleFooterFormOpen} />
    </div>
  )
}

export default DefaultLayout
