import React, { useState, useContext, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Dialog,
    Slide,
    useMediaQuery,
    useTheme,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    Chip,
    ListItemText,
} from "@mui/material";
import { UserContext } from "src/Contexts/UserContext";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { REGISTER_GRIEVANCE, FETCH_MODULE, DASHBOARD_URL } from "src/constants/api.config";
const notify = (message, type) => toast[type](message);
const FooterForm = ({ open, onClose }) => {
    const [userName, setUserName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [forModule, setForModule] = useState([]);
    const [modules, setModules] = useState([]);
    const [complainDescription, setComplainDescription] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { contextUserData } = useContext(UserContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleReset = () => {
        setUserName("");
        setMobileNumber("");
        setForModule([]);
        setComplainDescription("");
    };

    const modulesFetcher = async () => {
        try {
            const { data } = await axios.get(`${DASHBOARD_URL}/${FETCH_MODULE}`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
            });
            if (data.Links) {
                setModules(data.Links);
            }
        } catch (error) {
            console.log("error is here :", error.message)
            notify("Server Error", "error");
        }
    };

    useEffect(() => {
        modulesFetcher();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const params = new URLSearchParams({
            OperatorUserName: userName,
            MobileNo: mobileNumber,
            LoginUserID: contextUserData.LoginUserID,
            OfficerName: contextUserData.UserName,
            ComplainDesc: complainDescription,
            ForModule: forModule.join(","),
            LoginID: contextUserData.ErpUserID,
        });

        const config = {
            method: "GET",
            url: `${DASHBOARD_URL}/${REGISTER_GRIEVANCE}?${params.toString()}`,
            headers: { "Content-Type": "application/json; charset=utf-8" },
        };

        try {
            const result = await axios(config);
            console.log("result is here:", result)
            const { ErrCode, ErrMsg } = result.data.ErrMsg[0];
            if (ErrCode === "1" && ErrMsg) {
                notify(ErrMsg, "success");
            }
        } catch (error) {
            notify("Server Error", "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleModuleChange = (event) => {
        const {
            target: { value },
        } = event;
        setForModule(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <>
            {modules.length > 0 && (
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={Slide}
                    transitionDuration={500}
                    keepMounted
                    sx={{
                        "& .MuiDialog-paper": {
                            borderRadius: 2,
                            boxShadow: 3,
                            overflow: "hidden",
                        },
                    }}
                >
                    <Toaster />
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            p: 3,
                            backgroundColor: theme.palette.background.paper,
                        }}
                    >
                        <Typography
                            variant={isMobile ? "h5" : "h4"}
                            component="h1"
                            align="center"
                            gutterBottom
                        >
                            Register Grievance
                        </Typography>

                        {/* Display Login User ID */}
                        <TextField
                            fullWidth
                            label="Login User ID"
                            variant="filled"
                            value={contextUserData.LoginUserID || ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ mb: 2, background: "#eff6ff " }}
                        />

                        {/* Display Officer Name */}
                        <TextField
                            fullWidth
                            label="Officer Name"
                            variant="filled"
                            value={contextUserData.UserName || ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ mb: 2, background: "#eff6ff " }}
                        />

                        <TextField
                            fullWidth
                            label="User/Operator Name"
                            variant="filled"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            label="Mobile Number"
                            variant="filled"
                            type="tel"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            required
                            sx={{ mb: 2 }}
                        />

                        <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
                            <InputLabel id="forModule-label">For Module</InputLabel>
                            <Select
                                labelId="forModule-label"
                                value={forModule}
                                onChange={handleModuleChange}
                                multiple
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {modules.map((module) => (
                                    <MenuItem key={module.CODE} value={module.CODE}>
                                        <Checkbox checked={forModule.indexOf(module.CODE) > -1} />
                                        <ListItemText primary={module.DESCRIPTION} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Complaint Description"
                            variant="filled"
                            value={complainDescription}
                            onChange={(e) => setComplainDescription(e.target.value)}
                            multiline
                            rows={4}
                            required
                            sx={{ mb: 2 }}
                        />

                        <Box sx={{ display: "flex", justifyContent: "center", columnGap: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                disabled={isSubmitting}
                                sx={{
                                    width: "33%",
                                    mt: 0,
                                    padding: 1,
                                    borderRadius: 1,
                                    "&:hover": {
                                        transform: "translateY(-2px)",
                                        boxShadow: 2,
                                    },
                                }}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Submit"
                                )}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: "33%",
                                    mt: 0,
                                    padding: 1,
                                    borderRadius: 1,
                                    "&:hover": {
                                        transform: "translateY(-2px)",
                                        boxShadow: 2,
                                    },
                                }}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>

                            <Button
                                onClick={onClose}
                                variant="contained"
                                color="error"
                                sx={{
                                    width: "33%",
                                    mt: 0,
                                    padding: 1,
                                    borderRadius: 1,
                                    "&:hover": {
                                        transform: "translateY(-2px)",
                                        boxShadow: 2,
                                    },
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>

                </Dialog>
            )}
        </>
    );
};

export default FooterForm;
