import { React, useState, useEffect, useContext } from 'react'
import { CBadge } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilCalculator,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilChart,
  cilTablet,
  cilLink,
  cilExternalLink,
  cilStorage,
  cilChatBubble,
  cilTouchApp,

} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle, CLink, CNavLink } from '@coreui/react'

import { CK_Dsh_Gujmarg, CK_Dsh_Gujrams, CK_Dsh_Strobes } from './constants/api.config'

import "./scss/_nav.scss"
// let tbencrypted = JSON.parse(localStorage.getItem('loggedInUserData'))
// let encrypteddata = tbencrypted?.PlantMonitoringURL



// let encryptedURL = 'sec-rnb;admin@123'


// import { useContext } from 'react';
// import { UserContext } from '../context/UserContext';
// import { CNavGroup, CNavItem } from '@coreui/react';
// import CIcon from '@coreui/icons-react';
// import { cilChart } from '@coreui/icons';

const _nav = (UserData) => {

  if (!UserData?.UserRights?.length) {
    return [];
  }


  const iconObj = {
    "DSH_MAIN_DASHBOARD": <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    "DSH_SINGLE_SIGN_ON": <CIcon icon={cilTouchApp} customClassName="nav-icon" />,
    "WHS": <CIcon icon={cilChart} customClassName="nav-icon" />,
    "DSH_RNBD_LINKS": <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    "DSH_API_INTEGRATION": <CIcon icon={cilStorage} customClassName="nav-icon" />,
    "DSH_SUPPORT": <CIcon icon={cilChatBubble} customClassName="nav-icon" />,

  }

  function createNavObject(child, isIcon, encLoginUserID) {
    const isURL = (str) => /^https?:\/\//.test(str); // Check if the string is a URL

    const toOrUrl = child?.formobjname?.trim().replace(/[\r\n]/g, "") || "/*";

    const navObject = {
      component: CNavItem,
      name: child.menutitle,
      icon: isIcon ? <CIcon icon={cilLink} customClassName="nav-icon" /> : null,
      Level: child.Level,
      code: child.code,
      itemMenuOrder: child.menuorder,
      parentcode: child.parentcode,
      className: "small-nav-item",
    };

    if (isURL(toOrUrl) && child?.IsSSOURL.trim() === "Yes") {
      navObject.href = `${toOrUrl}?encryptedUrl=${encLoginUserID};abc1`
      navObject.target = "_blank";
    }
    else if (isURL(toOrUrl) && child?.IsSSOURL.trim() === "No") {
      navObject.href = toOrUrl
      navObject.target = "_blank";
    }
    else {
      navObject.to = toOrUrl;
    }

    return navObject;
  }



  const normalSideBarArray = UserData.UserRights.map((userRight) => ({
    component: userRight.Level.trim() === "FirstLevel" ? CNavGroup : CNavItem,
    name: userRight.menutitle,
    // to: "/home",
    icon: iconObj[userRight.code.trim()] ? iconObj[userRight.code.trim()] : <CIcon icon={cilChart} customClassName="nav-icon" />,
    Level: userRight.Level,
    code: userRight.code,
    order: userRight.menuorder,
    parentcode: userRight.parentcode,
    items: userRight.children?.map((child) => (createNavObject(child, false, UserData?.EncLoginUserID))),
  }));

  const searchFeatSideBarArray = UserData.UserRights.map((userRight) =>
    userRight.children?.map((child) => (createNavObject(child, true, UserData?.EncLoginUserID)))
  ).flat();


  return { normalSideBarArray, searchFeatSideBarArray }
};

export default _nav;






// const _nav2 = [
//   {
//     component: CNavGroup,
//     name: 'Main Dashboard',
//     to: '/home',
//     icon: <CIcon icon={cilChart} customClassName="nav-icon" />,
//     items: [
//       {
//         component: CNavItem,
//         name: 'Home',
//         to: '/home'
//       },
//       // {
//       //   component: CNavItem,
//       //   name: 'Panchayat (MMGSY)',
//       //   to: '/dashboard/mmgsy'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'Panchayat (PMGSY)',
//       //   to: '/dashboard/pmgsy'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'Panchayat (Other Roads)',
//       //   to: '/dashboard/otherroads'
//       // },
//       {
//         component: CNavItem,
//         name: 'Scada',
//         to: '/dashboard/scada'
//       },
//       // {
//       //   component: CNavItem,
//       //   name: 'GujRAMS',
//       //   to: '/dashboard/gujrams'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'National Highway',
//       //   to: '/dashboard/nh'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'State',
//       //   to: '/dashboard/wms'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'Capital',
//       //   to: '/dashboard/capital'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'P&P Buildings',
//       //   to: '/buildings/pp'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'STC Buildings',
//       //   to: '/buildings/stc'
//       // },
//       {
//         component: CNavItem,
//         name: 'GujMARG',
//         to: '/dashboard/gujmarg'

//       },
//       // {
//       //   component: CNavItem,
//       //   name: 'HR',
//       //   to: '/dashboard/hrms'
//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'IWDMS Dashboard',
//       //   to: '/dashboard/iwdms'

//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'IFMS Dashboard',
//       //   to: '/dashboard/ifms'

//       // },
//       {
//         component: CNavItem,
//         name: 'GujRAMS',
//         to: '/dashboard/gujrams'

//       },
//       {
//         component: CNavItem,
//         name: 'Contractor',
//         to: '/dashboard/contractor'
//       },
//       {
//         component: CNavItem,
//         name: 'Employee Data',
//         to: '/dashboard/hr'
//       },
//       // {
//       //   component: CNavItem,
//       //   name: 'GUJMARG',
//       //   to: '/dashboard/gujmarg'

//       // },
//       // {
//       //   component: CNavItem,
//       //   name: 'E-RAP Dashboard',
//       //   to: '/dashboard/hrms'

//       // },
//     ]
//   },
//   {
//     component: CNavGroup,
//     name: 'R&BD Application',
//     to: '/',
//     icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
//     items: [
//       {
//         component: CNavLink,
//         name: 'Contractor Registration',
//         href: "http://103.27.120.198/RNBCR/Login.aspx?encryptedURl=",
//         target: "_blank"
//       },
//       {
//         component: CNavLink,
//         name: 'Plant Monitoring',
//         href: "http://103.27.120.198/RNB_PM/login.aspx?encryptedURl=",
//         target: "_blank"
//       },
//       {
//         component: CNavLink,
//         name: 'Patch Work Monitoring',
//         href: "https://103.27.120.198/RNB_pwm/login.aspx?encryptedURl=",
//         target: "_blank"
//       },

//       {
//         component: CNavLink,
//         name: 'HR- Employee Data',
//         href: "http://103.27.120.198/RNBHR_USER/Login.aspx?encryptedURl=",
//         target: "_blank"
//       },
//       {
//         component: CNavItem,
//         name: 'WMS',
//         href: 'https://sso.gujarat.gov.in/SSO.aspx?Rurl=http://rnbwms.guj.nic.in/main/index.php',
//         target: "_blank",
//       },
//       {
//         component: CNavItem,
//         name: 'SATHI',
//         href: 'https://sathi.gujarat.gov.in/irj/portal',
//         target: "_blank",
//       },
//       {
//         component: CNavItem,
//         name: 'IFMS',
//         href: 'https://ifms2.gujarat.gov.in/ifms/#/',
//         target: "_blank",
//       },
//       {
//         component: CNavLink,
//         name: 'LAQ',
//         href: "https://bhoomirashi.gov.in/",
//         target: "_blank"
//       },
//       {
//         component: CNavLink,
//         name: 'GujRAMS',
//         href: `${CK_Dsh_Gujrams}/Login`,
//         target: "_blank"
//       },
//       {
//         component: CNavLink,
//         name: 'GujMarg',
//         href: `${CK_Dsh_Gujmarg}/#/Login`,
//         target: "_blank"
//       },

//       {
//         component: CNavLink,
//         name: 'State WMS',
//         href: `${CK_Dsh_Strobes}/auth/single-click-login?username=Y2VhYw==&role=Mw==&circle_id=MA==&division_id=MA==&sub_division_id=MA==&name=c2VhYyBjaGFuZGtoZWRh&token=NG5hb3JuVlVyRk5WeHpoR3c9PSIsInZ`,
//         target: "_blank"
//       }
//       // {
//       //   component: CNavLink,
//       //   name: 'DMS_R&BD',
//       //   href: "http://103.27.120.198/RNB_DMS/Login.aspx",
//       //   target:"_blank"
//       // },
//       // {
//       //   component: CNavLink,
//       //   name: 'HR_R&BD',
//       //   href: "http://103.27.120.198/RNBHR/Login.aspx",
//       //   target:"_blank"
//       // },

//     ],
//   },
//   {
//     component: CNavGroup,
//     name: 'API Integration Data',
//     to: '/',
//     icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
//     items: [
//       {
//         component: CNavItem,
//         name: 'Strobes',
//         to: '/dashboard/wms',
//       },
//       {
//         component: CNavItem,
//         name: 'MMGSY',
//         to: '/dashboard/mmgsy',
//       },
//       {
//         component: CNavItem,
//         name: 'API Logs',
//         to: '/dashboard/apilogs',
//       },

//     ],
//   },
//   {
//     component: CNavGroup,
//     name: 'Help',
//     to: '/',
//     icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
//     items: [
//       {
//         component: CNavItem,
//         name: 'Login and Use Applcation',
//         href: { loginpdf },
//         target: "_blank",
//       },
//       {
//         component: CNavItem,
//         name: 'Filter Charts',
//         href: '/assets/documents/FilterCharts.pdf',
//         target: "_blank",
//       },
//       {
//         component: CNavItem,
//         name: 'View All Data',
//         href: '/assets/documents/ViewAllData.pdf',
//         target: "_blank",
//       },
//       // {
//       //   component: CNavLink,
//       //   name: 'State WMS-Add Work',
//       //   href: "https://strobes.in/auth/single-click-login?username=Y2VhYw==&role=Mw==&circle_id=MA==&division_id=MA==&sub_division_id=MA==&name=c2VhYyBjaGFuZGtoZWRh&token=NG5hb3JuVlVyRk5WeHpoR3c9PSIsInZ",
//       //   target:"_blank"
//       // },
//       // {
//       //   component: CNavLink,
//       //   name: 'DMS_R&BD',
//       //   href: "http://103.27.120.198/RNB_DMS/Login.aspx",
//       //   target:"_blank"
//       // },
//       {
//         component: CNavLink,
//         name: 'HR_R&BD',
//         href: "http://103.27.120.198/RNBHR/Login.aspx",
//         target: "_blank"
//       },
//       {
//         component: CNavLink,
//         name: 'Contractor Registration',
//         href: "http://103.27.120.198/RNBCR/Login.aspx",
//         target: "_blank"
//       },
//     ],
//   }
//   // ,{
//   //   component: CNavTitle,
//   //   name: 'Features',
//   // },
//   // {
//   //   component: CNavLink,
//   //   name: 'Config',
//   //   href:'http://localhost/RNB_ADM_DASH/Webpages/frmConfigDashBoard.aspx',
//   //   target:"_blank",
//   //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
//   // },
//   // {
//   //   component: CNavLink,
//   //   name: 'Data Correction',
//   //   href:'http://localhost/RNB_ADM/Login.aspx',
//   //   target:"_blank",
//   //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
//   // },
//   // {
//   //   component: CNavGroup,
//   //   name: 'Data Warehouse',
//   //   to: '/datawarehouse/RPMS/RawData',
//   //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
//   //   items:[
//   //     {
//   //       component: CNavGroup,
//   //       name: 'RPMS',
//   //       to:'/',
//   //       items:[{
//   //         component: CNavGroup,
//   //         name: 'Raw Data',
//   //         items:[{
//   //           component: CNavItem,
//   //           name: 'Road Data',
//   //           to: '/datawarehouse/RPMS/[1,1,"Roads"]',

//   //         },
//   //         {
//   //           component: CNavItem,
//   //           name: 'Bridges Data',
//   //           to: '/datawarehouse/RPMS/[1,1,"Bridges"]',
//   //         }
//   //         ]},
//   //       {
//   //         component: CNavGroup,
//   //         name: 'Processed Data',
//   //         items:[{
//   //           component: CNavItem,
//   //           name: 'Master Table',
//   //           to: '/datawarehouse/RPMS/[1,2,"Master"]',

//   //         },
//   //         {
//   //           component: CNavItem,
//   //           name: 'Contractor',
//   //           to: '/datawarehouse/RPMS/[1,2,"Contractor"]',
//   //         },
//   //         {
//   //           component: CNavItem,
//   //           name: 'Pending Work',
//   //           to: '/datawarehouse/RPMS/[1,2,"Pending"]',
//   //         },
//   //         {
//   //           component: CNavItem,
//   //           name: 'Delay Data',
//   //           to: '/datawarehouse/RPMS/[1,2,"Delay"]',
//   //         },

//   //         ]
//   //       }]
//   //     },
//   //     {
//   //       component: CNavGroup,
//   //       name: 'State WMS',
//   //       to:'/',
//   //       items:[{
//   //         component: CNavGroup,
//   //         name: 'Raw Data',
//   //         items:[{
//   //           component: CNavItem,
//   //           name: 'ALL Data',
//   //           to: '/datawarehouse/RPMS/[2,106,"ALL"]',
//   //         }
//   //         ]
//   //       }]
//   //       },
//   //       {
//   //         component: CNavGroup,
//   //         name: 'GujRams',
//   //         to:'/',
//   //         items:[{
//   //           component: CNavGroup,
//   //           name: 'Raw Data',
//   //           items:[{
//   //             component: CNavItem,
//   //             name: 'Roads Data',
//   //             to: '/datawarehouse/RPMS/[10,10,"Roads"]',
//   //           },
//   //           {
//   //             component: CNavItem,
//   //             name: 'Accident Data',
//   //             to: '/datawarehouse/RPMS/[10,10,"Accident"]',
//   //           }
//   //           ]
//   //         }]
//   //         },
//   //     // {
//   //     //   component: CNavGroup,
//   //     //   name: 'GujRAMS',
//   //     //   to: '/dashboard'
//   //     // },
//   //   ]
//   // }
//   // {
//   //   component: CNavItem,
//   //   name: 'User Creation',
//   //   to: '/register',
//   //   icon: <CIcon icon={cilTablet} customClassName="nav-icon" />,
//   // },
// ]


