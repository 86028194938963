import 'react-app-polyfill/stable';
import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import ReactDOM from 'react-dom';
import { loadConfig } from './constants/api.config';


// export let config = {}

const oldLoadConfig = async () => {
  console.log("loadConfig got rendered:")
  try {

    const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
    if (!response.ok) {
      throw new Error('Failed to load configuration file');
    }
    return await response.json();
  } catch (error) {
    console.error('Error loading config.json:', error);
  }
};

// Function to load the configuration and then render the app
window.RenderApp = async (config) => {

  const configFile = await oldLoadConfig()
  console.log("configfile from indexjs here:", config)
  loadConfig(configFile)
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

// Call renderApp to load the config and render the app
window.RenderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
