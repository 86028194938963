import { Suspense, useEffect } from 'react'
import { useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, HashRouter } from 'react-router-dom'
import { loadConfig } from './constants/api.config'
import './scss/style.scss'
//import Login from './views/pages/login/newLogin.jsx'
//import Register from './views/pages/Register/Register.jsx'
import { getJWTfromCookie } from './utils/handleCookie.js'
import DefaultLayout from './layout/DefaultLayout'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ChangePassword from './views/pages/login/ChangePassword'
import axios from 'axios'

// import { ReactQueryDevtools, ReactQueryDevtoolsPanel } from 'react-query/devtools'
import './index.css'
import 'antd/dist/reset.css'
import { Toaster } from 'react-hot-toast'

import { lazy } from 'react'

import { UserContext } from './Contexts/UserContext'
import { Details_Table } from './views/dashboard/Details_Table'
import { store } from './redux/store'
import { Provider } from 'react-redux'

// import ForgotPasswordUserID from './views/pages/ForgotPages/ForgotPasswordUserID'
const Login = lazy(() => import('./views/pages/login/newLogin.jsx'))
const Register = lazy(() => import('./views/pages/Register/Register3'))
const ForgotUserID = lazy(() => import('./views/pages/ForgotPages/ForgotUserID'))
const ForgotPassword = lazy(() => import('./views/pages/ForgotPages/ForgotPassword'))
const UpdateUserDetails = lazy(() => import('./views/pages/UpdateUser/UpdateUserDetails'))
const ErrorPage = lazy(() => import("./components/ErrorPage"))

// const NewGujMarg = lazy(() => import('./views/dashboard/NewComponents/GujMargNew'))

//const DefaultLayout = lazy(()=>import('./layout/DefaultLayout'))
import EditCustomAgGrid from './components/shared/EditCustomAgGrid'
import { AddRecord } from './components/shared/AddRecord'
import { ShowAllData } from './components/shared/ShowAllData'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


// Pages
const ProtectedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)} />
const PublicRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Redirect to={{ pathname: '/home' }} /> : <Component {...props} />)} />
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    },
  },
})

window.NavigationDetails = {}

const App = () => {
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches
  const [activeSelection, setActiveSelection] = useState(true)
  const [encryptedURL, setEncryptedURL] = useState(true)
  const [loadinFlag, setLoadingFlag] = useState(false)
  const [contextUserData, setContextUserData] = useState({})
  // const [redirectToLogin, setRedirectToLogin] = useState(true)
  const history = useHistory()
  // const isURL = (str) => /^https?:\/\//.test(str);



  useEffect(() => {
    // localStorage.removeItem("jwt_token")
    const localStorageString1 = localStorage.getItem("RNB_LOGGED_IN_USER_DATA")
    const localStorageString2 = localStorage.getItem("jwt_token")
    if (!localStorageString1 || localStorageString2 !== "test") {
      localStorage.removeItem("RNB_LOGGED_IN_USER_DATA")
      localStorage.removeItem("jwt_token")
    }
    else {

      const loginData = JSON.parse(localStorageString1)

      if (loginData) {
        setContextUserData(loginData)
      }



    }



  }, [])





  if (isMobile) {
    return (
      <>
        <div style={{ textAlign: 'center', marginTop: '100%' }}>
          <p>
            <b>Mobile View Under Construction</b>
          </p>
          <p>
            <b>Please use application on Desktop</b>
          </p>
        </div>
      </>
    )
  }
  return (
    <Provider store={store}>
      <UserContext.Provider value={{ activeSelection, setActiveSelection, encryptedURL, setEncryptedURL, contextUserData, setContextUserData }}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtoolsPanel isOpen={true}></ReactQueryDevtoolsPanel> */}
          <HashRouter>
            <Toaster />
            <Suspense fallback={<div> Loading...</div>}>
              <Switch>
                <PublicRoute exact path="/" name="Login Page" component={() => <Login />} />
                <ProtectedRoute
                  path="/home"
                  component={() => (
                    <>
                      <DefaultLayout loadingFlag={loadinFlag} />
                    </>
                  )}
                />
                <ProtectedRoute path="/homeBuilding" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/watchlist" component={() => <DefaultLayout />} />
                <PublicRoute exact path="/login" name="Login Page" component={() => <Login />} />
                <PublicRoute exact path="/signup" name="Signup page" component={() => <Register />} />
                <PublicRoute exact path="/forgotpassword" name="ForgotPassword page" component={() => <ForgotPassword />} />
                <PublicRoute exact path="/forgotuserid" name="ForgotUserid page" component={() => <ForgotUserID />} />

                <ProtectedRoute path="/dashboard" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/buildings" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/tabledetails" component={() => <Details_Table />} />
                <ProtectedRoute path="/edittable" component={() => <EditCustomAgGrid />} />
                <ProtectedRoute path="/datawarehouse" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/details" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/childTable" component={() => <DefaultLayout />} />
                <ProtectedRoute path="/addrecord" component={() => <AddRecord />} />
                <ProtectedRoute exact path="/changepassword" name="Change password page" component={() => <ChangePassword />} />
                <ProtectedRoute exact path="/updateuserdetails" name="update user deatils page" component={() => <UpdateUserDetails />} />

                {/* <PublicRoute exact path="/testroute2" name="testing route 2" component={() => <NewGujMarg />} /> */}


                <ProtectedRoute path="/showalldata" component={() => <ShowAllData />} />
                <Route
                  path="/unauthorized"
                  render={() => (
                    <ErrorPage
                      errCode="403 - Unauthorized"
                      errMessage="Oops! You do not have permission to access this page."
                    />
                  )}
                />

                <Route path="*" component={() => <ErrorPage errCode={"404 - Page Not Found"} errMessage={"Oops! The page you're looking for doesn't exist."} />} />
              </Switch>
            </Suspense>
          </HashRouter>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </UserContext.Provider>
    </Provider>
  )
}

export default App
